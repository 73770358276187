@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.template-topper {
    header {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1030;
    }
}

@include media-breakpoint-up(lg) {
    .template-topper {
        .component-sidebar {
            padding-top: 74px !important;
        }
    }
}
