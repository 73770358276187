@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/variables';

@import '../../css/mixins';
@import '../../css/variables';

#physician-finder-no-action-exit {
    .modal-body {
        margin-right: 10%;
        margin-left: 10%;
    }
    h2 {
        margin-bottom: 2.5rem;
        font-size: 1.375rem;
        font-family: 'BentonSans-Bold';
        color: $heading-color2;
        text-align: center;
    }
    b {
        display: inline-block;
        margin-bottom: 2px;
        font-family: 'BentonSans-Bold';
        color: $heading-color2;
    }
    .point {
        margin-bottom: 35px;
    }
    .icon {
        text-align: center;
        margin-bottom: 20px;
        img {
            width: 75px;
        }
    }
    .content {
        text-align: center;
    }
    .cta {
        margin-top: 55px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        .leave-page {
            margin-top: 20px;
            font-size: 1.375rem;
            font-family: 'BentonSans-Bold';
        }
    }
    .footnotes {
        margin-top: 44px;
        font-size: 1rem;
    }
}

@include media-breakpoint-up(lg) {
    #physician-finder-no-action-exit {
        .close {
            width: 52px;
            height: 52px;
        }
        .modal-body {
            margin-right: 10%;
            margin-left: 10%;
        }
        h2 {
            margin-top: 2rem;
            margin-bottom: 3.5rem;
            font-size: 2.4375rem;
        }
        .point {
            display: flex;
            flex-direction: row;
            margin-bottom: 55px;
        }
        .icon {
            flex: 0 0 130px;
            margin-bottom: 0;
            text-align: center;
            img {
                width: auto;
            }
        }
        .content {
            margin-left: 35px;
            text-align: left;
            font-size: 1.375rem;
            line-height: 1.5em;
        }
        .cta {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex-flow: row-reverse;

            .leave-page {
                font-size: 1.5rem;
                margin-top: 0;
                margin-right: 50px;
            }
        }
        .footnotes {
            margin-top: 100px;
        }
    }
}

@include media-breakpoint-up(xl) {
    #physician-finder-no-action-exit {
        .main-points {
            width: 80%;
            margin: 0 auto;
        }
    }
}
