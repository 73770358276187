@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

@import '../../css/variables';

.component-testimonial {
    display: flex;
    flex-direction: row;
    margin: $mobile-padding * 0.5 $mobile-padding;
    border-radius: 10px;
    box-shadow: 0 3px 26px rgba(227, 226, 220, 0.5);
    background-color: $primary;

    .callout-icon {
        display: inline-block;
        overflow: hidden;
        border-radius: 10px 0 0 10px;
        width: 35.7348703170029%;
    }

    .callout-body {
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        width: 64.2651296829971%;
        padding: 0 20px;
        color: #fff;

        .callout-heading {
            font-family: 'BentonSans-Bold';
            font-size: 1.0625rem;
            margin-bottom: 16px;
            line-height: 1.25em;
        }

        .btn-animation {
            display: inline-flex;
            max-width: fit-content;
            width: auto;

            &::before {
                background: #fff;
                border: 1px solid $primary;
                border-radius: 35px;
            }

            &::after {
                background: $primary;
                border: 1px solid transparent;
                border-radius: 35px;
                border-color: #fff;
            }

            span {
                font-family: 'BentonSans-Bold';
                font-size: 1rem;
                color: $primary;
            }

            &:hover {
                span {
                    color: #fff;
                }
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .component-testimonial {
        .callout-body {
            padding: 0 16px 0 24px;
            .callout-heading {
                font-family: 'BentonSans-Bold';
                font-size: 1.75rem;
                margin-bottom: 28px;
            }

            .btn-animation {
                span {
                    font-size: 1rem;
                    padding-left: 30px;
                    padding-right: 30px;
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .component-testimonial {
        border-radius: 10px;
        margin: 0 10px 10px 0;

        .callout-icon {
            width: auto;
        }

        .callout-body {
            padding: 0 26px 0 64px;
            .callout-heading {
                font-size: 3.75rem;
            }
        }
    }
}
