@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

@import '../../css/mixins';
@import '../../css/variables';

.component-sidebar {
    display: none;
}

@include media-breakpoint-up(lg) {
    .component-sidebar {
        display: block;
        position: fixed;
        z-index: 2;
        .logo {
            padding-left: $sidebar-padding-left;
        }
        nav {
            margin-top: 32px;
            div {
                padding-left: $sidebar-padding-left;
                padding-top: 10px;
                padding-bottom: 10px;
                padding-right: 25px;
                border-top-right-radius: 35px;
                border-bottom-right-radius: 35px;
            }
            a, a:visited {
                color: $primary;
                font-family: 'BentonSans-Bold';
                font-size: 1.375rem;
                line-height: 1.25em;

                &:after {
                    background: $primary;
                }
            }
        }
        .buttons {
            margin-top: 25px;
            padding-left: $sidebar-padding-left - 20;
        }
    }
}
