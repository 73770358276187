@import '../../css/variables';

.component-terms {
    h4 {
        font-family: 'BentonSans-Bold';
        font-size: 1rem;
        color: $heading-color2;
    }
    p {
        color: $heading-color2;
    }
}