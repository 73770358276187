@import '../../css/variables';

.component-safety-info {
    h4 {
        font-family: 'BentonSans-Bold';
        font-size: 1.125rem;
    }
    .important {
        font-size: 1.1875rem;
    }
}