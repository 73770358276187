@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

@import '../../css/variables';

.component-form {
    .form-check {
        padding-left: 37px;
        .form-check-input {
            width: 24px;
            height: 24px;
            margin-left: -37px;
            &:focus {
                box-shadow: none;
            }

            &[type='radio'] {
                border-color: $primary;

                &:checked {
                    background-image: none;
                    background-color: $primary;
                }
            }

            &[type='checkbox'] {
                &:checked {
                    border-color: $primary;
                    background-color: $primary;
                }
            }
        }
        .form-check-label {
            font-family: 'BentonSans-Book';
            font-weight: 400;
            font-size: 1.125rem;
            color: #000;
            position: relative;
            top: 4px;
        }
    }
    .form-floating {
        & > .form-control {
            height: 56px;
            font-family: 'BentonSans-Book';
            font-weight: 400;
            font-size: 1.125rem;
            color: #000;

            &:not(:placeholder-shown) {
                & ~ .m-notch {
                    .m-notch-between {
                        label {
                            color: $text-color !important;
                        }
                    }
                }
            }
        }

        & > .dropdown {
            & > .dropdown-toggle {
                min-height: 56px;
                font-family: 'BentonSans-Book';
                font-weight: 400;
                font-size: 1.125rem;
                color: #000;
                line-height: 1em;
            }

            &.float {
                & ~ .m-notch {
                    .m-notch-between {
                        label {
                            color: $text-color !important;
                        }
                    }
                }
            }
        }

        .m-notch .m-notch-before,
        .m-notch .m-notch-between,
        .m-notch .m-notch-after {
            border: 1px solid rgba(0,0,0,0.12);
        }

        .m-notch .m-notch-before {
            border-right: 0;
        }

        .m-notch .m-notch-between {
            border-left: 0;
            border-right: 0;

            label {
                font-family: 'BentonSans-Book';
                font-weight: 400;
                font-size: 1.125rem;
                color: #000 !important;
            }
        }

        .m-notch .m-notch-after {
            border-left: 0;
        }

        .dropdown-menu {
            background-color: #F2F2F2;
            border-radius: 0;
            box-shadow: none;

            .dropdown-item {
                font-family: 'BentonSans-Bold';
                font-weight: 700;
                font-size: 1.125rem;
                color: $primary;
                transition: background-color .5s, color .5s;

                &.checked {
                    color: #000;
                    background-color: transparent;
                }

                &:hover {
                    color: #fff;
                    background-color: $primary;
                }
            }
        }
    }

    fieldset {
        legend {
            font-family: 'BentonSans-Book';
            font-size: 1.25rem;
            font-weight: 400;
            line-height: 1.2em;
            letter-spacing: 0.018em;
            color: #000;
        }

        .form-check {
            padding-top: 36px;
        }
    }
}

@include media-breakpoint-up(xl) {
    .component-form {
        fieldset {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;

            legend {
                width: 100%;
            }

            .form-check {
                padding-top: 0;
            }
        }
    }
}
