/*
   Style mixins
 ---------------------------------------------------------------------------*/
@mixin gradient($direction, $list) {
    background: -webkit-linear-gradient($direction, $list);
    background: -moz-linear-gradient($direction, $list);
    background: -o-linear-gradient($direction, $list);
    background: linear-gradient($direction, $list);
}

/*
   Transition mixins
 ---------------------------------------------------------------------------*/
@mixin rotate($degrees) {
    -webkit-transform: rotate($degrees);
    -moz-transform: rotate($degrees);
    -ms-transform: rotate($degrees);
    -o-transform: rotate($degrees);
    transform: rotate($degrees);
}

@keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    20% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@function svg-icon($name: 'chevron-right', $fill: '#fff') {

    // Set fill color
    $encodedFillColor: '%23'+str-slice('#{$fill}', 2, -1);

    // Get the icon by name
    $result: map-get(('chevron-right' : url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10.787" height="16.18" viewBox="0 0 10.787 16.18"><path id="svg-chevron" data-name="Chevron" d="M27.7,24.312a1.354,1.354,0,0,0-1.9.172l-5.707,6.847-5.707-6.847a1.348,1.348,0,1,0-2.07,1.728h0l6.742,8.09a1.348,1.348,0,0,0,2.07,0l6.742-8.09A1.35,1.35,0,0,0,27.7,24.312Z" transform="translate(-24 28.179) rotate(-90)" fill="currentColor"/></svg>'),
            ), $name);
    @return($result);
}

@mixin set-page($name) {
    @media #{$desktop} {
        ##{$name} {
            .component-sidebar {
                nav {
                    #nav-#{$name} {
                        background: $secondary;

                        a {
                            &::after {
                                background: transparent !important;
                            }

                            &:hover {
                                cursor: auto;
                            }
                        }
                    }
                }
            }

            main {
                background: url('/images/main-bg-#{$name}.webp') no-repeat;
                background-position: top left;
            }
        }
    }

    @media #{$mobile} {
        ##{$name} {
            .component-sidebar {
                nav {
                    #nav-#{$name} {
                        background: $secondary;

                        a {
                            &::after {
                                background: transparent !important;
                            }

                            &:hover {
                                cursor: auto;
                            }
                        }
                    }
                }
            }

            main {
                background: url('/images/main-bg-#{$name}.webp') no-repeat;
                background-position: top center;
                background-size: cover;
                background-size: auto 550px;
                position: relative;

                &::after {
                    content: '';
                    display: block;
                    background: #fff;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 450px;
                    left: 0;
                    z-index: -1;
                }
            }
        }
    }
}
