@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

@import '../css/variables';

.template-home {
    .home-hidden {
        display: none !important;
    }

    main {
        .content-callout {
            margin-top: 85px;
        }

        .safety-info {
            padding: $mobile-padding * 3 $mobile-padding $mobile-padding * 2;
        }
    }
}

@include media-breakpoint-up(lg) {
    .template-home {
        .wrapper {
            display: flex;
            flex-direction: row;
        }

        aside {
            position: absolute;
            flex: 0 0 $sidebar-width;
            padding-top: 44px;
            z-index: 4;

            .scrolled {
                padding-bottom: 44px;
                background-color: rgba(0, 0, 0, 0.75);
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
            }

            .component-sidebar {
                z-index: 4;
                padding-top: 44px;

                nav {
                    a {
                        color: #fff;

                        &::after {
                            background: #fff;
                        }
                    }
                }

                .btn-animation,
                .btn-animation:visited {
                    padding-top: 5px;
                    padding-bottom: 5px;
                    border: 2px solid #fff;
                    line-height: 1.5em;

                    &:before {
                        background: rgba(0, 0, 0, 0.5);
                    }

                    &::after {
                        background: $secondary;
                    }

                    &:hover {
                        color: $primary;
                    }

                    span {
                        &::after {
                            margin-left: 10px;
                        }
                    }
                }
            }
        }

        main {
            width: 100%;
            padding-bottom: $content-margin-bottom;
            display: flex;
            flex-direction: column;
            align-items: center;

            .slider {
                height: 780px;
                width: 100%;
                background: $home-nav-bg-color;
            }

            .callouts {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                background: $home-callouts-bg;
                border-bottom: 2px solid $accent;
            }

            .content-callout,
            .safety-info {
                max-width: 990px;
                margin-top: 90px;
                margin-left: $content-margin-left;
                padding: $mobile-padding;
            }

            .content-callout {
                .callout-header {
                    position: static;
                }
            }

            .safety-info {
                background: #fff;
            }
        }
    }
}
