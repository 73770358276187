@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

@import '../../css/variables';
@import '../../css/mixins';

.component-back-to-top {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 50px;
    top: -31px;
    width: 62px;
    height: 62px;
    border-radius: 50%;
    padding: 12px;
    background-color: $primary;
    transition: background-color 0.3s ease-out;
    &::after {
        display: none;
    }

    &:hover {
        background-color: $secondary;
    }
}

@media #{$mobile} {
    .component-back-to-top {
        right: 26px;
        top: -23px;
        width: 46px;
        height: 46px;
    }
}
