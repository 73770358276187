@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

@import '../css/variables';
@import '../css/mixins';

.template-subpage {
    .subpage-hidden {
        display: none !important;
    }
}

.template-subpage {
    main {
        width: 100%;
        padding-bottom: $content-margin-bottom;

        .heading {
            padding: 7.5 * $mobile-padding $mobile-padding $mobile-padding;
            text-align: center;
        }

        .content {
            margin: 0 $mobile-padding $mobile-padding;
            position: relative;
            padding: $mobile-padding * 2 $mobile-padding $mobile-padding;
            z-index: 0;

            &::before {
                content: ' ';
                position: absolute;
                width: 100%;
                height: 50%;
                top: 0;
                left: 0;
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
                background: #fff;
                box-shadow: 0px 3px 26px 10px rgba(227, 226, 220, 0.2);
                z-index: -1;
            }

            &::after {
                content: ' ';
                position: absolute;
                width: 100%;
                height: 50%;
                bottom: 0;
                left: 0;
                background: #fff;
                z-index: -1;
            }
        }

        .safety-info {
            padding: $mobile-padding;
            background: #fff;
        }

        .component-safety-info {
            margin-top: $mobile-padding;
        }
    }
}

@include media-breakpoint-down(lg) {
    .template-subpage {
        main {
            section {
                &.full-width {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .template-subpage {
        .wrapper {
            display: flex;
            flex-direction: row;
        }

        aside {
            flex: 0 0 $sidebar-width;
            padding-top: 92px;
        }

        main {
            min-height: 600px;
            padding-bottom: $content-margin-bottom;

            section {
                max-width: 990px;
                margin-left: $content-margin-left;

                &.full-width {
                    padding-right: 0;
                }
            }

            .heading {
                margin-top: $content-margin-top;
                padding: 0;
                text-align: left;
            }

            .content {
                margin-top: 40px;
                margin-left: $content-margin-left;
                padding: 61px 68px 20px 56px;

                &::before {
                    box-shadow: 0px 3px 26px 10px rgba(227, 226, 220, 0.5);
                }
            }

            .safety-info {
                margin-top: 215px;
            }

            .component-safety-info {
                margin-top: 30px;
            }

            .container-testimonials {
                margin-top: 57px;
                max-width: 1080px;
            }

            .container-resources {
                margin-top: 12px;
            }
        }
    }
}

@media screen and (min-width: 1480px) {
    .template-subpage {
        main {
            .container-resources {
                display: flex;
                flex-wrap: wrap;

                &>.component-resource {
                    flex: calc(50% - 10px);
                }
            }
        }
    }
}

@include set-page('healing-navigator');
@include set-page('physician-finder');
@include set-page('resources');
@include set-page('insurance-coverage');
