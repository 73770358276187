@import '../../css/variables';
@import '../../css/mixins';

.component-footer {
    position: relative;
    padding-bottom: 40px;
    a, a:visited {
        color: $text-color;
        &:hover {
            color: $text-color;
        }
        &::after {
            background-color: $text-color;
        }
    }

    .links {
        font-size: 1.125rem;
        font-family: 'BentonSans-Bold';
        a {
            display: inline-block;
        }
    }

    .legal, .copyright {
        p {
            margin: 0;
        }
        a, a:visited {
            &::after {
                content: '';
                height: 1px;
                position: absolute;
                bottom: 0;
                left: 0;
                background: $text-color;
                width: 100%;
                transition: width ease-in-out 0.2s;
            }
        }
        a:hover {
            &::after {
                width: 0;
            }
        }
    }
}

@media #{$desktop-after-xl} {
    .component-footer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-top: 60px;
        padding-bottom: 30px;

        .logo {
            margin-top: 5px;
        }

        .content {
            margin-left: 70px;
        }

        .links {
            display: flex;
            flex-direction: row;

            .link {
                padding: 0;

                &::after {
                    content: '|';
                    display: inline-block;
                    position: relative;
                    top: -1px;
                    width: 16px;
                    text-align: center;
                }
                &:first-child {
                    padding-left: 0;
                }
                &:last-child {
                    &::after {
                        content: '';
                        display: none;
                    }
                }
            }
        }
        .legal, .copyright {
            margin-top: 23px;
        }
        a {
            &::after {
                background: $text-color;
            }
        }
    }
}

@media #{$desktop-before-xl} {
    .component-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 66px 5% 80px 20%;

        .logo, .content {
            width: 100%;
        }
        .links, .legal, .copyright {
            margin-top: 23px;
        }
    }
}

@media #{$mobile} {
    .component-footer {
        padding: 66px 10% 80px 10%;

        .links {
            .link {
                padding: 5px 0;
            }
        }
    }
}