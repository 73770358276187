@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/variables';

@import '../../css/mixins';
@import '../../css/variables';

.component-video-modal {
    top: 50px;

    .modal-body {
        position: relative;
        overflow-y: auto;
        padding: 0;
    }

    .close {
        flex-grow: 0;
        flex-shrink: 0;
        display: block;
        position: absolute;
        right: 15px;
        top: 15px;
        padding: 0;
        margin: 0;
        width: 26px;
        height: 26px;
        background-image: url(/images/icon-close-modal.svg);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        opacity: 1;
        transition: opacity .3s ease-in;
        z-index: 100;

        &:hover {
            opacity: .85;
        }
    }

    .callout {
        margin-top: -20px;
        display: none;
        opacity: 0;
        animation: fadeInFromNone 0.5s ease-out;
        z-index: 1200;
        transition: transform ease 300ms;

        &.shown {
            display: block;
            margin-top: 0;
            opacity: 1;
        }

        .component-callout-help-for-healing {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 15;
            bottom: -15%;
            left: -40%;
            transform: scale(0.25);
            width: 900px;


            a {
                position: relative;
                display: flex;
                flex-direction: row;
                padding: 80px 85px 85px 300px;

                .callout-header {
                    position: absolute;
                    left: 20px;
                    top: -10px;
                    width: auto;

                    img {
                        position: relative !important;
                        left: 0;

                        &:first-child {
                            display: none !important;
                        }

                        &:last-child {
                            display: block !important;
                        }
                    }
                }

                .callout-body {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    padding-left: 90px;

                    .callout-heading {
                        position: static;
                    }
                }

                &:hover {
                    box-shadow: 0 3px 26px rgba(227, 226, 220, 0.5);
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .component-video-modal {
        .callout {
            .component-callout-help-for-healing {
                width: 900px;
                transform: scale(0.25);
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .component-video-modal {
        top: 0;

        .close {
            width: 36px;
            height: 36px;
            right: 24px;
            top: 24px;
        }

        .callout {
            .component-callout-help-for-healing {
                width: 900px;
                bottom: -5%;
                left: -5%;
                transform: scale(0.475);
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .component-video-modal {
        .callout {
            .component-callout-help-for-healing {
                width: 1050px;
                left: 5%;
                transform: scale(0.475);
            }
        }
    }
}
