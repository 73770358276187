@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

@import '../../css/variables';

.component-form-physician-finder {
    margin: 0 $mobile-padding 0;
    position: relative;
    z-index: 0;

    .form-select-label {
        font-size: 1.625rem;
        color: #000;
    }

    .form-floating {
        width: initial;

        &>.dropdown {
            &>.dropdown-toggle {
                border-radius: 40px;
                height: 73px;
                padding-left: 30px !important;
                padding-right: 50px !important;
                background-image: none;
                box-shadow: 0 3px 6px rgba(0, 0, 0, .16) !important;
                font-family: 'BentonSans-Bold';
                font-weight: 700;
                font-size: 1.625rem;
                color: $primary;
                width: 200px;

                &::after {
                    content: '';
                    display: block;
                    width: 20px;
                    height: 16px;
                    -webkit-mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
                    mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
                    -webkit-mask-size: cover;
                    mask-size: cover;
                    background-color: $primary;
                    border: none;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 30px;
                }

                &[aria-expanded="true"] {
                    background-color: $primary;
                    color: #fff;

                    &::after {
                        background-color: #fff;
                    }
                }
            }

            .dropdown-menu {
                max-width: 200px;
                background-color: $dropdown-bg;

                .dropdown-item {
                    justify-content: center;
                    padding: 10px 10px;
                    font-family: 'BentonSans-Bold';
                    font-size: 1.625rem;
                    color: $primary;

                    &.checked {
                        color: #000000;
                        background-color: $dropdown-bg;
                    }

                    &:not(.checked):hover {
                        background: $primary;
                        color: #fff;
                    }
                }
            }
        }

        .m-notch .m-notch-before,
        .m-notch .m-notch-between,
        .m-notch .m-notch-after {
            border: 0;
        }
    }

    .form-physician-finder-initial {
        padding: 35px 50px 50px;
        background: #fff;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;

        h3 {
            font-family: 'BentonSans-Book';
            font-weight: 400;
            font-size: 1.375rem;
            line-height: 1.45em;
            text-align: center;
            color: $heading-color;
            margin-bottom: 36px;

            span {
                font-family: 'BentonSans-Bold';
            }
        }

        .zip-input {
            border-radius: 0;
            outline: none;
            max-width: 180px;
            height: 73px;
            border: 1px solid #707070;
            font-family: 'BentonSans-Book';
            font-size: 2.75rem;
            font-weight: 400;
            line-height: 1.68em;
            text-align: center;
            color: $heading-color;
        }

        .radius-label {
            font-family: 'BentonSans-Book';
            font-weight: 400;
            font-size: 1.5rem;
            line-height: 3.04em;
            margin-bottom: 0;
            color: $heading-color;
        }

        .component-form {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: stretch;

            .zip-holder,
            .label-holder,
            .radius-holder,
            .submit-holder {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
            }

            .submit-holder {
                padding-top: 38px;
                width: 100%;
            }
        }
    }

    .form-physician-finder-results {
        display: none;
        overflow: hidden;
        background: #fff;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        padding-bottom: 60px;

        &.no-results .table-responsive {
            display: none;
        }

        .table-head {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding: 30px;
            background: #fff;

            .left {
                font-family: 'BentonSans-Book';
                font-weight: 400;
                font-size: 1.375rem;
                line-height: 1.18em;
                margin-bottom: 28px;

                span {
                    color: $primary;
                }
            }

            .add {
                cursor: pointer;
            }

            .reset {
                display: none;
                cursor: pointer;
            }
        }

        .table-foot {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 30px 10px;


            &::before {
                content: '';
                display: block;
                height: 16px;
                position: absolute;
                top: -16px;
                left: 0;
                right: 0;
                overflow: hidden;
                background: linear-gradient(transparent, rgba(0, 0, 0, .05));
            }

            .reset {
                font-size: 1.25rem;
                line-height: 1.2em;
                cursor: pointer;
            }
        }

        .table {
            margin-bottom: 0;
            background: #fff;
            // box-shadow: 0px 3px 26px 10px rgba(227, 226, 220, 0.2);
            // box-shadow: 0px 3px 26px 10px rgba(227, 226, 220, 0.5);

            th {
                border-top: 0;
                border-bottom: 0;
                padding-top: 0;
                padding-bottom: 20px;
                font-family: 'BentonSans-Bold';
                font-weight: 700;
                color: $text-color;
                vertical-align: top;
                padding-left: 30px;
                padding-right: 18px;

                // Enable once location is shown
                // &:first-child,
                // &:nth-child(4),
                // &:last-child {
                //     display: none;
                // }
                &.col-state,
                &.col-specialty,
                &.col-institution {
                    display: none;
                }
            }

            td {
                border-top: 0;
                border-bottom: 0;
                padding-top: 15px;
                padding-bottom: 15px;
                font-family: 'BentonSans-Book';
                font-weight: 400;
                color: $text-color;
                padding-left: 30px;
                padding-right: 18px;

                // Enable once location is shown
                // &:first-child,
                // &:nth-child(4),
                // &:last-child {
                //     display: none;
                // }
                &.col-state,
                &.col-specialty,
                &.col-institution {
                    display: none;
                }

                span {
                    display: none;
                }

                .call {
                    font-family: 'BentonSans-Bold';
                    font-weight: 700;
                    color: $primary;

                    span {
                        position: relative;
                        display: inline-block;
                        &::after {
                            content: url('/images/icon-phone.svg');
                            width: 14px;
                            height: 14px;
                            position: absolute;
                            right: -20px;
                            top: 1px;
                        }
                    }
                }
            }

            &>tbody {
                &>tr {
                    background-color: transparent !important;

                    &:nth-of-type(even)>* {
                        background-color: #FFF;
                    }

                    &:nth-of-type(odd)>* {
                        background-color: rgba(248, 248, 248, 0.5);
                    }
                }
            }
        }

        .download-resources {
            h5 {
                color: $heading-color;
                font-family: 'BentonSans-Book';
                font-size: 1.25rem;
                text-align: center;
            }

            .resources {
                &>.component-resource {
                    margin: 0 0 $mobile-padding 0;

                    .callout-body {
                        padding: 0 10px 0 20px;

                        .callout-heading {
                            margin-bottom: 0;
                            font-size: 1.25rem;
                        }

                        .btn-animation {
                            display: none;
                        }
                    }

                    &:hover {
                        box-shadow: 0 3px 26px rgba(227, 226, 220, 1);
                    }
                }
            }
        }
    }

    .form-physician-finder-results-empty {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 38px 10px;

        p {
            text-align: center;
            margin-bottom: 40px;
            font-size: 1.125rem;
            font-family: 'BentonSans-Book';
            font-weight: 400;
            line-height: 1.4em;
            color: #000;
        }
    }

    &::before {
        content: ' ';
        position: absolute;
        width: 100%;
        height: 50%;
        top: 0;
        left: 0;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        z-index: -1;
    }

    &::after {
        content: ' ';
        position: absolute;
        width: 100%;
        height: 50%;
        bottom: 0;
        left: 0;
        background: #fff;
        z-index: -1;
    }
}

@include media-breakpoint-up(lg) {
    .component-form-physician-finder {
        margin-top: 40px;
        margin-left: $content-margin-left;

        .form-physician-finder-initial {
            h3 {
                font-size: 2.75rem;
                line-height: 1.68em;
            }

            .radius-label {
                font-size: 2.75rem;
                line-height: 1.68em;
            }

            .component-form {
                flex-direction: row;
                justify-content: flex-start;
                align-items: stretch;
                flex-wrap: wrap;

                .zip-holder {
                    flex-grow: 1;
                    flex-shrink: 1;
                    flex-basis: 10%;
                    justify-content: flex-end;
                }

                .label-holder {
                    flex-grow: 0;
                    flex-shrink: 0;
                    padding-left: 17px;
                    padding-right: 17px;
                }

                .radius-holder {
                    flex-grow: 1;
                    flex-shrink: 1;
                    flex-basis: 10%;
                    justify-content: flex-start;
                }

                .submit-holder {
                    padding-top: 58px;
                    flex-grow: 0;
                    flex-shrink: 0;
                }
            }
        }

        .form-physician-finder-results {

            & > .table-wrapper {
                min-height: 400px;
            }

            .table-head {
                flex-direction: row;
                align-items: start;

                .left {
                    flex-grow: 0;
                    flex-shrink: 0;
                    margin-bottom: initial;
                    padding-right: 20px;
                }

                .right {
                    flex-grow: 1;
                    flex-shrink: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-end;
                }

                .add,
                .reset {
                    text-align: right;
                }

                .add {
                    margin-bottom: 10px;
                }

                .reset {
                    display: inline-block;
                }
            }

            .table-foot {
                padding: 10px 0 0;

                .reset {
                    display: none;
                }
            }

            .table {
                th {
                    &.col-state,
                    &.col-zip,
                    &.col-hcp,
                    &.col-specialty,
                    &.col-institution {
                        display: table-cell;
                    }
                }

                td {

                    &.col-state,
                    &.col-zip,
                    &.col-hcp,
                    &.col-specialty,
                    &.col-institution {
                        display: table-cell;
                    }

                    span {
                        display: inline;
                    }

                    .call {
                        display: none;
                    }
                }
            }

            .download-resources {
                h5 {
                    font-size: 1.5rem;
                    text-align: left;
                }

                .resources {
                    display: flex;
                    flex-wrap: wrap;

                    &>.component-resource {
                        flex: 0 0 calc(33.333% - 40px);
                        margin: 0 $mobile-padding $mobile-padding 0;

                        &+.component-resource {
                            margin-right: $mobile-padding;
                        }

                        .callout-body {
                            padding: 0 10px 0 20px;

                            .callout-heading {
                                margin-bottom: 0;
                                font-size: 1.125rem;
                            }

                            .btn-animation {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .form-physician-finder-results-empty {
            padding: 100px 10px;

            p {
                margin-bottom: 20px;
                font-size: 1.625rem;
                font-family: 'BentonSans-Medium';
            }

            a {
                font-size: 1.625rem;
                font-family: 'BentonSans-Book';
                margin-top: 60px;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .component-form-physician-finder {
        .form-physician-finder-initial {
            padding: 188px 70px 100px;

            .component-form {

                .label-holder {
                    padding-left: 48px;
                    padding-right: 17px;
                }
            }
        }

        .form-physician-finder-results {
            .table-head {
                .left {
                    font-size: 1.5rem;
                    line-height: 1.25em;
                }

                .add,
                .reset {
                    font-size: 1.25rem;
                    line-height: 1.2em;
                }
            }
        }
    }
}
