@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

@import '../../css/variables';

$padding: 50px;
$close: 52px;
$header-padding-top-mobile: 18px;
$header-padding-bottom-mobile: 15px;
$header-padding-top-desktop: 28px;
$header-padding-bottom-desktop: 26px;

.component-form-registration {
    transform: none !important;
    .modal-dialog {
        margin: 0;
        max-width: initial;
        width: 100%;
        height: calc(100vh - var(--navigation-height-mobile));
        top: var(--navigation-height-mobile);
        transform: scale(0.9) !important;
        opacity: 0;
        transition: transform .3s ease-in, opacity .3s ease-in;
    }

    .modal-content {
        border: none;
        border-radius: 0;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
    }

    .close {
        flex-grow: 0;
        flex-shrink: 0;
        display: block;
        position: absolute;
        right: $padding * 0.3;
        top: $padding * 0.36;
        padding: 0;
        margin: 0;
        width: $close;
        height: $close;
        background-image: url(/images/icon-close-modal.svg);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        opacity: 1;
        transition: opacity .3s ease-in;

        &:hover {
            opacity: .85;
        }
    }

    .modal-header {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        padding: ($padding * 0.36) (($padding * 0.3) + $close) ($padding * 0.3) $padding;
        display: block;
        border-bottom: 0;
    }

    .modal-body {
        padding: 0 $padding;
    }

    .modal-footer {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        padding: 0 $padding $padding * 0.72;
        border-top: 0;
    }

    .modal-form {
        .modal-header {
            .modal-title {
                font-family: 'BentonSans-Bold';
                font-weight: 700;
                font-size: 1.75rem;
                color: $heading-color2;
                line-height: 1.357em;
                text-align: left;
                padding-top: $close;
            }
        }

        .modal-body {
            .description {
                font-family: 'BentonSans-Book';
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.625em;
                color: $heading-color2;
                text-align: left;
                margin-bottom: $padding * 0.34;
            }
        }

        .row {
            margin-top: 0;
            margin-left: $padding * -0.2;
            margin-right: $padding * -0.2;
            .col-12, .col-6 {
                padding-left: $padding * 0.2;
                padding-right: $padding * 0.2;
                margin-bottom: $padding * 0.4;

                &.rules {
                    text-align: right;
                    margin-bottom: $padding * 0.32;
                    font-family: 'BentonSans-Book';
                    font-size: 0.875rem;
                    font-weight: 400;
                    color: #000;
                }

                &.i-have {
                    padding-top: $padding * 0.4;
                    padding-bottom: $padding * 0.9;
                }

                &.terms-agree {
                    padding-top: $padding * 0.4;
                    padding-bottom: $padding * 0.9;

                    .form-check {
                        .form-check-label {
                            color: $text-color;
                            font-size: 1rem;
                            top: 2px;
                        }
                    }
                }

                &.submit {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: flex-start;

                    .btn-animation {
                        min-width: 250px;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .modal-form-message {
        .modal-header {
            .modal-title {
                font-family: 'BentonSans-Bold';
                font-weight: 700;
                font-size: 3rem;
                color: $heading-color2;
                line-height: 1.375em;
                text-align: center;
                padding-top: $padding * 2.92;
            }
        }

        .modal-body {
            .description {
                font-family: 'BentonSans-Book';
                font-weight: 400;
                font-size: 1.25rem;
                line-height: 1.6em;
                color: $heading-color2;
                text-align: center;
                margin-bottom: $padding * 2;
            }

            .btn-animation {
                display: block;
                margin: 0 auto;
                max-width: 270px;

                span {
                    width: 100%;
                }

                &.btn-white {
                    margin: 16px auto 0;
                }
            }
        }
    }

    &.show {
        .modal-dialog {
            transform: scale(1) !important;
            opacity: 1;
        }
    }
}

@include media-breakpoint-up(lg) {
    .modal-backdrop {
        display: block;
        background-color: #6F6F6F;
        opacity: 0.5;
    }
    .component-form-registration {
        .modal-dialog {
            max-width: 900px;
            height: initial;
            margin: 1.75rem auto;
            top: initial;
        }

        .close {
            top: $padding * 0.56;
            right: $padding * 0.52;
        }

        .modal-header {
            padding: $padding * 0.56 $padding * 3.3;
        }

        .modal-body {
            padding: 0 $padding * 3.3;
        }

        .modal-footer {
            padding: 0 $padding * 3.3 $padding * 1.62;
        }

        .modal-form {
            .modal-header {
                .modal-title {
                    font-size: 2.375rem;
                    line-height: 1.28em;
                    padding-top: $padding * 0.5;
                }
            }

            .modal-body {
                .description {
                    font-size: 1.125rem;
                    margin-bottom: $padding * 0.92;
                }
            }

            .row {
                margin-left: -0.1 * $padding;
                margin-right: -0.1 * $padding;

                .col-12, .col-6 {
                    padding-left: 0.1 * $padding;
                    padding-right: 0.1 * $padding;
                    margin-bottom: 0.32 * $padding;

                    &.rules {
                        margin-bottom: 0.24 * $padding;
                    }

                    &.i-have {
                        padding-top: 0.74 * $padding;
                        padding-bottom: 0.74 * $padding;
                    }

                    &.terms-agree {
                        padding-top: 0.86 * $padding;
                        padding-bottom: 0.86 * $padding;
                    }

                    &.submit {
                        justify-content: flex-start;

                        .btn-animation {
                            min-width: 170px;
                        }
                    }
                }
            }
        }

        .modal-form-message {
            .modal-header {
                .modal-title {
                    padding-top: $padding * 2.06;
                }
            }

            .modal-body {
                padding-bottom: $padding * 1.16;
                .description {
                    margin-bottom: $padding * 1.4;
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .component-form-registration {
        .modal-dialog {
            max-width: 1150px;
        }
    }
}
