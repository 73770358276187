@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/variables';

@import '../../css/mixins';
@import '../../css/variables';

.component-info-modal {
    .modal-dialog {
        .close {
            flex-grow: 0;
            flex-shrink: 0;
            display: block;
            position: absolute;
            right: 15px;
            top: 15px;
            padding: 0;
            margin: 0;
            width: 26px;
            height: 26px;
            background-image: url(/images/icon-close-modal.svg);
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            opacity: 1;
            transition: opacity .3s ease-in;
            z-index: 100;

            &:hover {
                opacity: .85;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .component-info-modal {
        .close {
            width: 36px;
            height: 36px;
            right: 24px;
            top: 24px;
        }
    }
}
