@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

body {
    &.scroll-disabled {
        overflow: hidden;
    }
}

.component-header {
    display: block;
    width: 100%;
    height: var(--navigation-height-mobile);
    overflow: hidden;
    position: fixed;
    z-index: 1030;
    transition: height .3s ease-in;
    text-align: left;

    .main-nav {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        overflow: hidden;
        width: inherit;
        height: inherit;
        padding-left: 20px;
        padding-right: 20px;
    }

    #toggle {
        padding: 0;
        flex-grow: 0;
        flex-shrink: 0;
        position: relative;
        z-index: 2;
        height: var(--navigation-height-mobile);
        width: var(--navigation-height-mobile);
        border: none;
        background: transparent;

        svg {
            stroke: var(--color-white);
            transition: .2s;

            line {
                stroke-width: 3px;
                stroke-linecap: round;
                stroke-linejoin: round;
            }
        }

        g {
            &:first-child {
                opacity: 1;
                transition: opacity 0s .2s;

                line {
                    transition: transform .2s .2s;
                    transform: translateY(0px);
                }
            }

            &:last-child {
                opacity: 0;
                transition: opacity 0s .2s;

                line {
                    transition: transform .2s;
                    transform: rotate(0deg);
                    transform-origin: center;
                }
            }
        }
    }

    #logo {
        flex-grow: 1;
        flex-shrink: 1;
        position: relative;
        z-index: 2;
        height: var(--navigation-height-mobile);
        padding-right: var(--navigation-height-mobile);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #nav {
        flex-grow: 1;
        flex-shrink: 1;
        width: 100%;
        height: calc(100vh - var(--navigation-height-mobile));
        position: relative;
        z-index: 2;
        overflow-y: scroll;
        padding-top: 55px;
        padding-bottom: 100px;
        padding-left: 20px;
        padding-right: 20px;
        -ms-overflow-style: none;
        scrollbar-width: none;

        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                padding-bottom: 40px;
                a {
                    font-family: 'BentonSans-Bold';
                    font-size: 1.375rem;
                    line-height: 1.25em;
                    color: var(--color-white);

                    &::after {
                        background: var(--color-white);
                    }
                }

                &:last-child {
                    padding-bottom: 0;
                }
            }
        }

        .buttons  {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 85px;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        width: inherit;
        height: var(--navigation-height-mobile);
        top: 0;
        left: 0;
        right: 0;
        background-color: #13739A;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        z-index: 0;
        width: inherit;
        height: calc(100vh - var(--navigation-height-mobile));
        top: var(--navigation-height-mobile);
        left: 0;
        right: 0;
        background: transparent radial-gradient(closest-side at 50% 50%, #007297 0%, #04132A 200%) 0% 0% no-repeat padding-box;
    }

    &.collapsed {
        height: 100vh;

        #toggle {
            g {
                &:first-child {
                    opacity: 0;

                    line {
                        transition: transform .2s;

                        &:first-child {
                            transform: translateY(7px);
                        }

                        &:last-child {
                            transform: translateY(-7px);
                        }
                    }
                }

                &:last-child {
                    opacity: 1;

                    line {
                        transition: transform .2s .2s;

                        &:first-child {
                            transform: rotate(45deg);
                        }

                        &:last-child {
                            transform: rotate(-45deg);
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .component-header {
        text-align: center;
    }
}

@include media-breakpoint-up(lg) {
    .component-header {
        display: none;
    }
}
