@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

@import '../../css/variables';

.component-home-callout {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: $mobile-padding $mobile-padding * 2;
    border-bottom: 2px solid $accent;

    &:last-child {
        border-bottom: 0;
    }

    .callout-body {
        padding-left: $sidebar-padding-left;
        order: 2;
        width: 60%;

        .callout-heading {
            display: inline-block;
            font-family: 'BentonSans-Bold';
            font-size: 1.5rem;
            color: $primary;
            line-height: 1.1875em;
            padding-right: 10px;

            &::after {
                content: '';
                display: inline-block;
                color: $secondary;
                -webkit-mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10.787" height="16.18" viewBox="0 0 10.787 16.18"><path id="svg-chevron" data-name="Chevron" d="M27.7,24.312a1.354,1.354,0,0,0-1.9.172l-5.707,6.847-5.707-6.847a1.348,1.348,0,1,0-2.07,1.728h0l6.742,8.09a1.348,1.348,0,0,0,2.07,0l6.742-8.09A1.35,1.35,0,0,0,27.7,24.312Z" transform="translate(-24 28.179) rotate(-90)" fill="black"/></svg>');
                mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10.787" height="16.18" viewBox="0 0 10.787 16.18"><path id="svg-chevron" data-name="Chevron" d="M27.7,24.312a1.354,1.354,0,0,0-1.9.172l-5.707,6.847-5.707-6.847a1.348,1.348,0,1,0-2.07,1.728h0l6.742,8.09a1.348,1.348,0,0,0,2.07,0l6.742-8.09A1.35,1.35,0,0,0,27.7,24.312Z" transform="translate(-24 28.179) rotate(-90)" fill="black"/></svg>');
                background-color: currentColor;
                width: 11px;
                height: 16px;
                position: relative;
                top: 2px;
                right: -10px;
            }
        }

        .callout-content {
            display: none;
            font-size: 1.5rem;
            line-height: 1.333em;
            color: $text-color;
            padding-top: 16px;
            max-width: 90%;
            color: $heading-color;
        }
    }

    .callout-icon {
        flex: 1 0.1 auto;
        order: 1;
        width: 40%;
    }
}

@include media-breakpoint-up(lg) {
    .component-home-callout {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding-top: 30px;
        padding-bottom: 30px;
        border-bottom: 0;
        width: 100%;

        &::before {
            content: '';
            display: inline-block;
            height: 174px;
            border: 2px solid $accent;
            position: absolute;
            left: 0;
        }

        .callout-body {
            width: 100%;
            padding-left: 0;
            text-align: center;
        }

        .callout-icon {
            flex: 0;
            width: 100%;
            padding-bottom: 30px;
            text-align: center;
        }
    }
}

@include media-breakpoint-up(xl) {
    .component-home-callout {
        flex-direction: row;
        align-items: flex-start;
        padding: $mobile-padding * 2 $mobile-padding * 2.5;

        .callout-body {
            display: flex;
            flex-direction: column;
            order: 1;
            text-align: left;
            width: 50%;

            &.w-55 {
                width: 55%;
            }

            .callout-heading {
                font-size: 2rem;
                padding-right: 40px;

                &::after {
                    top: 0;
                }
            }

            .callout-content {
                display: inline-block;
            }
        }

        .callout-icon {
            flex: 1 0.1 auto;
            order: 2;
            text-align: left;
            width: 50%;
            padding-left: $mobile-padding;

            &.w-45 {
                width: 45%;
            }
        }
    }
}
