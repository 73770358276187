@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/variables';

@import './variables';
@import './mixins';

@font-face {
  font-family: 'BentonSans-Book';
  src:
    url('/fonts/BentonSansStdBook/font.woff2') format('woff2'),
    url('/fonts/BentonSansStdBook/font.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'BentonSans-Regular';
  src:
    url('/fonts/BentonSansStdRegular/font.woff2') format('woff2'),
    url('/fonts/BentonSansStdRegular/font.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'BentonSans-Medium';
  src:
    url('/fonts/BentonSansStdMedium/font.woff2') format('woff2'),
    url('/fonts/BentonSansStdMedium/font.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'BentonSans-Bold';
  src:
    url('/fonts/BentonSansStdBold/font.woff2') format('woff2'),
    url('/fonts/BentonSansStdBold/font.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'BentonSansCond-Regular';
  src:
    url('fonts/BentonSansStdBlack/font.woff2') format('woff2'),
    url('fonts/BentonSansStdBlack/font.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'BentonSansCond-Medium';
  src:
    url('fonts/BentonSansStdMediumItalic/font.woff2') format('woff2'),
    url('fonts/BentonSansStdMediumItalic/font.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'BentonSansCond-Bold';
  src:
    url('fonts/BentonSansStdBoldItalic/font.woff2') format('woff2'),
    url('fonts/BentonSansStdBoldItalic/font.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
    font-family: 'icon';
    src: url('/fonts/icon.eot?dpczpe');
    src: url('/fonts/icon.eot?dpczpe#iefix') format('embedded-opentype'),
        url('/fonts/icon.ttf?dpczpe') format('truetype'),
        url('/fonts/icon.woff?dpczpe') format('woff'),
        url('/fonts/icon.svg?dpczpe#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FontAwesome-Bold';
    font-style: normal;
    font-weight: 900;
    font-display: auto;
    src: url("/fonts/FontAwesome-Bold.eot");
    src: url("/fonts/FontAwesome-Bold.eot?#iefix") format("embedded-opentype"),
        url("/fonts/FontAwesome-Bold.woff") format("woff");
}

@font-face {
    font-family: 'FontAwesome-Regular';
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src: url("/fonts/FontAwesome-Regular.eot");
    src: url("/fonts/FontAwesome-Regular.eot?#iefix") format("embedded-opentype"),
        url("/fonts/FontAwesome-Regular.woff") format("woff");
}

:root {
    --color-white: #fff;
    --navigation-height-mobile: 50px;
}

body {
    position: relative;
    color: $text-color;
    font-family: 'BentonSans-Book';
    font-size: 16px;
    overscroll-behavior-y: none;
}

sup {
    position: relative;
    top: -0.5em;
    font-size: 0.625em;
}

.wrapper {
    padding-top: var(--navigation-height-mobile);
}

a,
a:visited {
    display: inline-block;
    text-decoration: none;
    position: relative;
    color: $primary;

    &:hover {
        text-decoration: none;
        color: $primary;
    }

    &::after {
        content: '';
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        background: $primary;
        transition: 0.2s;
    }

    &:hover::after {
        width: 100%;
    }

    &.block {
        display: block;
        padding: 0.5em;

        &:hover {
            background: #fff;
        }
    }
    &.no-underline {
        &::after {
            background: transparent !important;
        }
    }
}

.btn-animation,
.btn-animation:visited {
    outline: none;
    background: transparent;
    border-radius: 35px;
    padding: 10px 20px;
    font-family: 'BentonSans-Bold';
    font-size: 1.25rem;
    position: relative;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    overflow: hidden;
    transition: all .5s;
    border: 0;

    &.btn-lg {
        font-size: 2rem !important;
        padding: 12px 36px 12px 40px;
    }

    span {
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 1;
        color: #fff;
        transition: color .5s;
        line-height: 1.20em;

        &::after {
            content: '';
            -webkit-mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10.787" height="16.18" viewBox="0 0 10.787 16.18"><path id="svg-chevron" data-name="Chevron" d="M27.7,24.312a1.354,1.354,0,0,0-1.9.172l-5.707,6.847-5.707-6.847a1.348,1.348,0,1,0-2.07,1.728h0l6.742,8.09a1.348,1.348,0,0,0,2.07,0l6.742-8.09A1.35,1.35,0,0,0,27.7,24.312Z" transform="translate(-24 28.179) rotate(-90)" fill="black"/></svg>');
            mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10.787" height="16.18" viewBox="0 0 10.787 16.18"><path id="svg-chevron" data-name="Chevron" d="M27.7,24.312a1.354,1.354,0,0,0-1.9.172l-5.707,6.847-5.707-6.847a1.348,1.348,0,1,0-2.07,1.728h0l6.742,8.09a1.348,1.348,0,0,0,2.07,0l6.742-8.09A1.35,1.35,0,0,0,27.7,24.312Z" transform="translate(-24 28.179) rotate(-90)" fill="black"/></svg>');
            background-color: currentColor;
            display: inline-block;
            position: relative;
            width: 11px;
            height: 16px;
            margin-left: 15px;
        }
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
    }

    &::after {
        background: $secondary;
        transform: translateX(-101%);
        transition: transform .5s;
    }

    &::before {
        background: $primary;
    }

    &:disabled {
        cursor: not-allowed;

        &::before {
            background: #727272;
        }
    }

    &:not(:disabled):hover {
        span {
            color: $primary;
        }

        &::after {
            transform: translateX(0);
        }
    }

    &.reverse {
        color: $secondary;

        &::before {
            background: $home-slider-btn-bg;
        }

        &::after {
            background: $secondary;
        }

        &:hover {
            color: $primary;
        }
    }

    &.btn-white {
        border: 1px solid $primary;
        span {
            color: $primary;
        }

        &::before {
            background: transparent;
        }
    }
}

h2 {
    font-family: 'BentonSans-Bold';
    font-size: 4.0625rem;
    color: $heading-color;
}

.subhead {
    font-family: 'BentonSans-Book';
    font-size: 2.75rem;
    color: $heading-color;
    line-height: 1.25em;
    margin-top: 15px;
}

h3 {
    font-family: 'BentonSans-Bold';
    font-size: 1.5rem;
    color: $heading-color;
}

.reference {
    font-size: 1rem;
    font-family: 'BentonSans-Book';
    color: $heading-color;

    h4 {
        font-family: 'BentonSans-Bold';
        font-size: 1rem;
        color: $heading-color;
    }
}

.em-24 {
    color: $heading-color;
    font-size: 1.25rem;
    font-family: 'BentonSans-Book';
    line-height: 1.5em;
}

.em-28 {
    color: $heading-color;
    font-size: 1.75rem;
    font-family: 'BentonSans-Book';
    line-height: 1.5em;
}

.em-88 {
    font-size: 5.5rem;
    font-family: 'BentonSans-Bold';
}

.img-max-400 {
    max-width: 400px;
    width: 100%;
}

footer {
    background: $accent;
}

@include media-breakpoint-down(lg) {
    h2 {
        font-size: 1.75rem;
    }

    .subhead {
        font-size: 1.5rem;
    }

    h3 {
        font-size: 1.25rem;
    }

    .reference {
        line-height: 1.25em;

        h4 {
            line-height: 1.25rem;
        }
    }
}

@include media-breakpoint-up(lg) {
    .wrapper {
        padding-top: 0;
    }
}

@include media-breakpoint-up(xl) {
    .mw-xl {
        max-width: 1080px !important;
    }
}
form {
  sup {
    color: red;
  }
}
