@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

@import '../../css/mixins';
@import '../../css/variables';

#component-topper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    background-color: $home-slider-btn-bg;
    color: $secondary;
    font-family: 'BentonSans-Bold';
    font-size: 0.875rem;
    transition: background-color 0.5s ease, color 0.5s ease;

    .btn-animation,
    .btn-animation:visited {
        margin-left: 8px;
        padding-top: 6px;
        padding-bottom: 5px;
        font-size: 0.875rem;
        color: $secondary;

        &::before {
            background: $secondary;
        }

        &::after {
            background: $home-slider-btn-bg;
        }

        &:hover {
            color: $secondary;
            span {
                color: $secondary;
            }
        }

        span {
            color: $home-slider-btn-bg;
            font-size: 0.875rem;
            &::after {
                transform: scale(1.2);
                position: relative;
                top: 0;
                margin-left: 13px;
            }
        }
    }

    &:hover {
        background-color: $secondary;
        color: $home-slider-btn-bg;
    }
}

@include media-breakpoint-up(lg) {
    #component-topper {
        height: 62px;
        font-size: 1.25rem;
        .btn-animation,
        .btn-animation:visited {
            margin-left: 11px;
            padding-top: 6px;
            padding-bottom: 5px;
            span {
                font-size: 1.25rem;
            }
        }
    }
}
