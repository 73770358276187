@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

@import '../../css/variables';

.component-healing-navigator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .quiz-heading {
        margin-bottom: 35px;
        font-family: 'BentonSans-Medium';
        font-size: 1.5rem;
        color: $heading-color;
        text-align: center;
    }

    .wound-selector {
        margin-right: $mobile-padding * 2;
        margin-left: $mobile-padding * 2;
        .option {
            input[type="checkbox"] {
                display: none;

                &+label {
                    cursor: pointer;

                    span {
                        display: none;
                    }

                    .label {
                        margin-top: 15px;
                        text-align: center;
                        font-size: 2rem;
                        color: $heading-color;
                    }
                }

                &:not(:checked)+label {
                    .off {
                        display: block;
                    }

                    .on {
                        display: none;
                    }
                }

                &:checked+label {
                    position: relative;

                    .off {
                        display: none;
                    }

                    .on {
                        display: block;
                    }

                    &::after {
                        content: '';
                        display: block;
                        width: 66px;
                        height: 66px;
                        position: absolute;
                        bottom: 15%;
                        right: 0;
                        background: url('/images/icon-checkmark.svg') no-repeat;
                    }
                }
            }

            &:last-child {
                label {
                    span {
                        padding-top: 26px;
                        padding-bottom: 26px;
                    }
                }
            }
        }
    }

    .button {
        margin-top: $mobile-padding * 2;
        text-align: center;

        .btn-animation {
            span {
                padding-left: 20px;
                padding-right: 20px;
            }
        }

        .references {
            margin-top: $mobile-padding * 2;
        }
    }

    .results {
        display: none;

        .retake-quiz {
            text-align: center;
            font-size: 1.5rem;
        }

        .results-heading {
            margin-top: 25px;
            font-family: 'BentonSans-Bold';
            font-size: 1.125rem;
            color: $heading-color;
            text-align: center;
        }

        .vlu-resources,
        .dfu-resources {
            display: none;
            text-align: left;

            .heading {
                padding: $mobile-padding 0;
                font-family: 'BentonSans-Bold';
                font-size: 1.125rem;
                line-height: 1.625em;
                color: $heading-color;
                text-align: left;
            }

            .resources {
                &>.component-resource {
                    margin: 0 0 $mobile-padding 0;
                    .callout-body {
                        padding: 0 10px 0 20px;

                        .callout-heading {
                            margin-bottom: 0;
                            font-size: 1.25rem;
                        }

                        .btn-animation {
                            display: none;
                        }
                    }

                    &:hover {
                        box-shadow: 0 3px 26px rgba(227, 226, 220, 1);
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .component-healing-navigator {
        .quiz-heading {
            margin-bottom: 76px !important;
            margin-top: 10px;
            padding-left: 50px;
            padding-right: 50px;
            font-size: 2.625rem;
            line-height: 1.25em;
        }

        .wound-selector {
            // order: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .option {
                width: 40%;
            }
        }

        .button {
            order: 3;

            .btn-animation {
                span {
                    padding-left: 60px;
                    padding-right: 60px;
                }
            }
        }

        .results {
            order: 4;
            text-align: center;

            .results-heading {
                margin-top: 50px;
                font-size: 1.5rem;
            }

            .vlu-resources,
            .dfu-resources {
                .heading {
                    margin-top: $mobile-padding * 2;
                    font-size: 1.625rem;
                }

                .resources {
                    display: flex;
                    flex-wrap: wrap;

                    &>.component-resource {
                        flex: 0 0 calc(33.333% - 40px);
                        margin: 0 $mobile-padding $mobile-padding 0;

                        & + .component-resource {
                            margin-right: $mobile-padding;
                        }

                        .callout-body {
                            padding: 0 10px 0 20px;

                            .callout-heading {
                                margin-bottom: 0;
                                font-size: 1.125rem;
                            }

                            .btn-animation {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
