@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/variables';

@import '../../css/variables';
@import '../../css/mixins';

.component-slider {
    width: 100%;
    display: block;
    z-index: -1;

    #desktop-slider {
        width: 100%;
        max-height: 780px;
        position: relative;
        overflow: hidden;
        z-index: 3;
    }

    #after-image {
        display: block;
        height: 830px;
        background: url('/images/home-right-bg.webp') no-repeat;
        background-position: top right;
        // background-size: 76%;

        .featured-image {
            position: absolute;
            right: 40px;
            top: 272px;
            width: 710px;
            height: 529px;
        }

        #after-message-bg {
            position: absolute;
            top: 249px;
            left: 649px;
            width: 1273px;
            height: 318px;
            /* UI Properties */
            display: none;
            background: #000000 0% 0% no-repeat padding-box;
            border-radius: 30px;
            opacity: 0.65;
            filter: blur(19px);
        }

        #after-message {
            position: absolute;
            top: 355px;
            left: 730px;
            display: none;
            font-family: 'BentonSans-Bold';
            font-size: 3.125rem;
            line-height: 1.2em;
            color: #fff;
            z-index: 1;
        }
    }

    #before-image {
        position: absolute;
        height: 100%;
        width: 50%;
        top: 0;
        left: 0;
        overflow: hidden;
        z-index: 2;
        background: url('/images/home-left-bg.webp') no-repeat;
        background-position: top left;
        background-size: cover;
        text-align: center;
        transition: width 0.3s ease-in;

        .blur {
            display: none;
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            background: url('/images/home-left-bg.webp') no-repeat;
            background-position: top left;
            background-size: cover;
            filter: blur(4px);
            z-index: 0;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 495px;
            height: 830px;
            background: transparent linear-gradient(180deg, #000000 0%, #00000000 100%) 0% 0% no-repeat padding-box;
            opacity: 1;
        }

        .featured-image {
            position: absolute;
            left: 330px;
            top: 222px;
            height: 100%;
            width: 587px;
            z-index: 1;
        }

        #before-message-bg {
            position: absolute;
            top: 249px;
            left: -39px;
            width: 1273px;
            height: 318px;
            display: none;
            background: #000000 0% 0% no-repeat padding-box;
            border-radius: 30px;
            opacity: 0.65;
            filter: blur(19px);
        }

        #before-message {
            position: absolute;
            top: 355px;
            left: 775px;
            display: none;
            width: 444px;
            height: 120px;
            font-family: 'BentonSans-Bold';
            font-size: 3.125rem;
            line-height: 1.2em;
            color: #fff;
            z-index: 1;
            text-shadow: 10px 3px 36px #000;
        }
    }

    #drag-toolbar {
        position: absolute;
        display: flex;
        align-items: center;
        z-index: 5;
        top: 0;
        left: 50%;
        height: 100%;
        width: 4px;
        transition: left 0.3s ease-in;

        #drag-left, #drag-right {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.86);
            position: absolute;
            width: 79px;
            height: 57px;
            bottom: 98px;
            cursor: pointer;
            transition: background-color 0.3s ease-in;

            &::after {
                content: url(/images/slider-drag-arrow.svg);
                width: 18px;
                height: 20px;
            }

            &:active, &:hover, &.active {
                background: $primary;
            }

            &.active {
                cursor: default;
            }
        }

        #drag-left {
            left: -79px;
            border-top-left-radius: 50px;
            border-bottom-left-radius: 50px;
        }

        #drag-right {
            left: 0;
            border-top-right-radius: 50px;
            border-bottom-right-radius: 50px;

            &::after {
                transform: scaleX(-1);
            }
        }
    }

    #callout {
        position: absolute;
        top: 206px;
        left: calc(50% - 170px);
        width: 340px;
        height: 365px;
        z-index: 2;
        background: $primary;
        transition: left 0.3s ease-in;

        .btn-animation,
        .btn-animation:visited {
            margin-left: 31px;
            color: $secondary;
            font-size: 1.1875rem;
            padding-left: 25px;
            padding-right: 25px;

            &::before {
                background: $home-slider-btn-bg;
            }

            &::after {
                background: $secondary;
            }

            &:hover {
                color: $primary;

                span {
                    color: $primary;
                }
            }

            span {
                color: $secondary;
                &::after {
                    margin-left: 10px;
                }
            }
        }
    }
}

@media #{$desktop-before-xl} {
    .component-slider {
        #after-image {
            #after-message-bg {
                top: 49px;
                left: 549px;
            }

            #after-message {
                top: 155px;
                left: 680px;
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .component-slider {
        #desktop-slider {
            display: none;
        }

        #mobile-slider {
            display: block;
            margin-top: 50px;

            .before-after-image {
                display: flex;
                flex-direction: row;
                position: relative;

                .before-image {
                    width: 50%;
                    padding-top: $mobile-padding;
                    background: url('/images/home-left-bg.webp') no-repeat;
                    background-position: bottom center;
                    background-size: 200%;
                    position: relative;

                    &.blur {
                        filter: blur(8px);
                    }

                    &:hover {
                        cursor: pointer;
                    }

                    img {
                        width: 80%;
                    }
                }

                .after-image {
                    width: 50%;
                    padding-top: $mobile-padding;
                    background: url('/images/home-right-bg.webp') no-repeat;
                    background-position: center center;
                    background-size: 200%;

                    &.blur {
                        filter: blur(8px);
                    }

                    &:hover {
                        cursor: pointer;
                    }

                    img {
                        position: relative;
                        top: 10%;
                    }
                }

                .before-message,
                .after-message {
                    position: absolute;
                    width: 50%;
                    height: 100%;
                    padding: 5%;
                    left: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-family: 'BentonSans-Bold';
                    font-size: 1.625rem;
                    color: #fff;
                    line-height: 1.2em;

                    &:hover {
                        cursor: pointer;
                    }
                }

                .before-message,
                .before-message-bg {
                    display: none;
                    left: 50%;
                }

                .after-message,
                .after-message-bg {
                    display: none;
                    left: 0;
                }

                .before-message-bg,
                .after-message-bg {
                    position: absolute;
                    width: 50%;
                    height: 100%;
                    background: #000000 0% 0% no-repeat padding-box;
                    opacity: 0.49;
                }
            }

            .callout {
                text-align: center;
                background: $primary;

                .image {
                    width: 50%;
                    max-width: 300px;
                    margin: 0 auto;
                    position: relative;
                    top: -15px;
                    padding-bottom: 20px;
                }

                .button-container {
                    height: 40px;
                    background: #fff;
                    position: relative;
                    z-index: 1;

                    .btn-animation,
                    .btn-animation:visited {
                        position: relative;
                        top: -25px;

                        span {
                            color: $secondary;
                        }

                        &::before {
                            background: $home-slider-btn-bg;
                        }

                        &::after {
                            background: $secondary;
                        }

                        &:hover {
                            color: $primary;

                            span {
                                color: $primary;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .component-slider {
        #desktop-slider {
            display: block;
        }

        #mobile-slider {
            display: none;
        }
    }
}
