@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

@import '../../css/variables';
@import '../../css/mixins';

.component-callout-help-for-healing {
    background: url('/images/callout-help-for-healing-bg.svg') no-repeat;
    background-position: center center;
    border-radius: 0;
    a {
        width: 100%;
        .callout-header {
            width: 100%;
            display: flex;
            align-items: center;

            img {
                position: relative;
                top: -60px;
                margin: 0 auto;
            }
        }
        .callout-body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #fff;

            .callout-heading {
                position: relative;
                margin-top: -35px;
                padding-bottom: 5px;
                font-family: 'BentonSans-Bold';
                font-size: 1.875rem;
                line-height: 1.2em;
            }

            .callout-content {
                font-size: 1.25rem;
                line-height: 1.2em;
            }

            .callout-button {
                .btn-animation,
                .btn-animation:visited {
                    color: $secondary;
                    position: relative;
                    top: 35px;
                    font-size: 1.5rem;
                    border: 2px solid #fff;

                    &::before {
                        background: $secondary;
                    }

                    &::after {
                        background: $primary;
                    }

                    &:hover {
                        color: $secondary;
                        box-shadow: 0 3px 36px $primary-dark;
                        span {
                            color: #fff;
                        }
                    }

                    span {
                        color: $primary;
                        &::after {
                            transform: scale(1.2);
                            position: relative;
                            top: -1px;
                            margin-left: 13px;
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .component-callout-help-for-healing {
        border-radius: 10px;

        a {
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding: 40px 85px 55px 85px;

            span {
                display: block;
            }

            .callout-header {
                width: 287px;

                img {
                    position: static;
                }
            }

            .callout-body {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                color: #fff;
                margin: 0;
                padding-left: 80px;

                .callout-heading {
                    font-size: 2.75rem;
                    line-height: 1.2em;
                }

                .callout-content {
                    margin-top: 10px;
                    font-size: 1.375rem;
                }

                .callout-button {
                    margin-top: 20px;
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .component-callout-help-for-healing {
        a {
            .callout-body {
                .callout-heading {
                    font-size: 3.75rem;
                }

                .callout-content {
                    font-size: 1.875rem;
                }
            }
        }
    }
}
