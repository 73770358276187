/*
    Media query variables
 ---------------------------------------------------------------------------*/
 $desktop: '(min-width: 992px)';
 $desktop-after-xl: '(min-width: 1201px)';
 $desktop-before-xl: '(max-width: 1200px)';
 $mobile-before-sm: '(max-width: 576px)';
 $mobile: '(max-width: 991px)';
 $mobile-desktop: '(min-width: 992px) and (max-width: 1200px)';
 $tablet: '(max-width: 1079px)';
 
 /*
    Colors variables
  ---------------------------------------------------------------------------*/
$primary: #007297;
$secondary: #F5C445;
$accent: #E3E2DC;
$home-nav-bg-color: #404040;
$home-slider-btn-bg: #02405D;
$home-callouts-bg: rgba(227, 226, 220, .23);
$primary-dark: #023C59;

$text-color: #707070;
$heading-color: #000;
$heading-color2: #3D4543;

$dropdown-bg: #f2f2f2;

$sidebar-width: 360px;
$sidebar-padding-left: 50px;
$content-margin-left: 95px;
$content-margin-top: 246px;
$content-margin-bottom: 30px;

$mobile-padding: 20px;
