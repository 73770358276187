@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

@import '../../css/variables';

.component-resource {
    display: flex;
    flex-direction: row;
    margin: $mobile-padding * 0.5 $mobile-padding;
    border-radius: 10px 10px 0 10px;
    box-shadow: 0 3px 26px rgba(227, 226, 220, 0.5);
    background-color: #fff;

    .callout-icon {
        display: inline-block;
        width: 35.7348703170029%;
        overflow: hidden;
        border-radius: 10px 0 0 10px;
    }

    .callout-body {
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        width: 64.2651296829971%;
        padding: 0 20px;


        .callout-heading {
            font-family: 'BentonSans-Bold';
            font-size: 1.0625rem;
            margin-bottom: 16px;
            line-height: 1.25em;
        }

        .btn-animation {
            display: inline-flex;
            max-width: fit-content;
            width: auto;

            span {
                font-family: 'BentonSans-Bold';
                font-size: 1rem;
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .component-resource {
        .callout-body {
            padding: 0 26px;
            .callout-heading {
                font-family: 'BentonSans-Bold';
                font-size: 1.5rem;
                margin-bottom: 28px;
            }

            .btn-animation {
                span {
                    font-size: 1rem;
                    padding-left: 30px;
                    padding-right: 30px;
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .component-resource {
        border-radius: 10px;
        margin: 0 10px 10px 0;
    }
}
